import { Environment, environmentsMap } from 'src/app/models/environment';
import { Type } from '@angular/core';
import { EventTokenEntryComponent } from 'src/app/components/info-entry/event-token-entry/event-token-entry.component';
import { NodeEventEntryComponent } from 'src/app/components/info-entry/node/node-event-entry/node-event-entry.component';
import { NodeEntryComponent } from 'src/app/components/info-entry/node/node-entry/node-entry.component';
import { EventDomainEntryComponent } from 'src/app/components/info-entry/event-domain-entry/event-domain-entry.component';

export interface InfoEntry {
  routeLink: string;
  environment: Environment;
  component: Type<any>;
  text: string;
}

export const infoEntries = {
  eventToken: (env: string, text: string) => ({
    routeLink: `/eventToken/${env}/${text}`,
    component: EventTokenEntryComponent,
    text,
    environment: environmentsMap[env],
  } as InfoEntry),

  eventDomain: (env: string, text: string) => ({
    routeLink: `/eventDomain/${env}/${text}`,
    component: EventDomainEntryComponent,
    text,
    environment: environmentsMap[env],
  } as InfoEntry),

  nodeId: (env: string, text: string) => ({
    routeLink: `/nodeId/${env}/${text}`,
    text,
    environment: environmentsMap[env],
    component: NodeEntryComponent,
  } as InfoEntry),
};
