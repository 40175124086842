import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Event } from 'src/models';

@Component({
  selector: 'app-node-event-entry',
  templateUrl: './node-event-entry.component.html',
  styleUrls: ['./node-event-entry.component.scss'],
})
export class NodeEventEntryComponent implements OnInit {

  node: Event;

  constructor() {
  }

  ngOnInit(): void {
  }

}
