<mat-progress-bar mode="indeterminate" *ngIf="node==null"></mat-progress-bar>
<h2>
  Node id: {{infoEntry?.text}}
</h2>
<mat-chip-list>
  <mat-chip selected
            [color]="infoEntry.environment.id === 'staging'?'warn':'primary'">
    ENV: {{infoEntry.environment.id}}</mat-chip>
  <mat-chip>Node Type: {{node?.__typename}}</mat-chip>
</mat-chip-list>
<div style="margin-top: 20px;">
  <div appNodeEntry [node]="node" *ngIf="node!=null"></div>
</div>
