import * as _ from 'lodash';
import { CmsSDKObservables, createSDKs } from 'src/utils/graphql-requests';

export interface Environment {
  id: string;
  sdk: CmsSDKObservables;
}

const sdks = createSDKs();
export const environments = [
  {
    id: 'production',
    sdk: sdks.production,
  },
  {
    id: 'staging',
    sdk: sdks.staging,
  },
];
export const environmentsMap = _.keyBy(environments, it => it.id);
