<div *ngIf="event!=null">
  <div>
    Event ID: {{event.id}}
  </div>
  <div>
    Event Name: {{event.name.values[0]?.value}}
  </div>
  <div>
    Event vexpo url: {{event.vexpoEventUrl}}
  </div>
</div>
