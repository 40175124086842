import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/models';

@Component({
  selector: 'app-event-content',
  templateUrl: './event-content.component.html',
  styleUrls: ['./event-content.component.scss']
})
export class EventContentComponent implements OnInit {

  @Input()
  event: Event;

  constructor() { }

  ngOnInit(): void {
  }
}
