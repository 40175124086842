import { Component, OnInit } from '@angular/core';
import { Booth, Event } from 'src/models';

@Component({
  selector: 'app-node-entry-booth',
  templateUrl: './node-entry-booth.component.html',
  styleUrls: ['./node-entry-booth.component.scss'],
})
export class NodeEntryBoothComponent implements OnInit {

  node: Booth;


  constructor() {
  }

  ngOnInit(): void {
  }

  get boothTier(): string {
    return this.node.tier?.name?.values?.[0]?.value;
  }

}
