import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** six-digit hexadecimal string representing red, green, blue components of a web color prefixed by hash symbol # */
  Color: string;
  /** Decimal number representing dimension of display measurement */
  DisplayMeasurementDimension: any;
  /** Represents untyped JSON */
  JSON: any;
  /** A language code in IANA Language Subtag Registry */
  Locale: string;
  /** Signed blob ID generated via `createDirectUpload` mutation */
  SignedBlobID: any;
  /** Standard error key path, could be either a String or Int */
  StandardErrorPathSegment: any;
  Upload: any;
}

/** Autogenerated input type of AddContact */
export interface AddContactInput {
  userUuid: Scalars['String'];
  contactUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of AddContact */
export interface AddContactPayload {
  __typename?: 'AddContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<RongcloudUser>;
  success: Scalars['Boolean'];
}

/** Attendee */
export interface Attendee {
  __typename?: 'Attendee';
  customAttendeeFields?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rongcloudToken?: Maybe<Scalars['String']>;
  rongcloudUserId?: Maybe<Scalars['String']>;
  tickets: AttendeeTicketConnection;
}


/** Attendee */
export interface AttendeeTicketsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Attendee */
export interface AttendeeTicket {
  __typename?: 'AttendeeTicket';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** The connection type for AttendeeTicket. */
export interface AttendeeTicketConnection {
  __typename?: 'AttendeeTicketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttendeeTicketEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AttendeeTicket>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AttendeeTicketEdge {
  __typename?: 'AttendeeTicketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AttendeeTicket>;
}

export interface Booth extends Node {
  __typename?: 'Booth';
  appliedDesignCodename?: Maybe<Scalars['String']>;
  appointmentBookingUrl?: Maybe<Scalars['String']>;
  boothDesign?: Maybe<BoothDesign>;
  boothDesignCanvas?: Maybe<BoothDesignCanvasElement>;
  boothNumber?: Maybe<Scalars['String']>;
  categories: BoothCategoryConnection;
  cmsUrl?: Maybe<Scalars['String']>;
  description: LocalizableString;
  designElements: BoothDesignElementConnection;
  event: Event;
  exhibitionName: LocalizableString;
  hall: Hall;
  id: Scalars['ID'];
  logo: LocalizableImage;
  /** To indicate whether meetup function is enabled */
  meetupEnabled?: Maybe<Scalars['Boolean']>;
  meetupExhibitorManagementUrl?: Maybe<Scalars['String']>;
  name: LocalizableString;
  previewUrl?: Maybe<Scalars['String']>;
  reportToken?: Maybe<Scalars['String']>;
  rootContentElement: BoothRootContentElement;
  rowOrder?: Maybe<Scalars['String']>;
  tier: BoothTier;
  website?: Maybe<Scalars['String']>;
}


export interface BoothCategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothDesignElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothCategory extends Node, BoothTaxonomy {
  __typename?: 'BoothCategory';
  /** ID of the object. */
  id: Scalars['ID'];
  name: LocalizableString;
  supercategory?: Maybe<BoothSupercategory>;
}

/** The connection type for BoothCategory. */
export interface BoothCategoryConnection {
  __typename?: 'BoothCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BoothCategoryEdge {
  __typename?: 'BoothCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothCategory>;
}

/** The connection type for Booth. */
export interface BoothConnection {
  __typename?: 'BoothConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Booth>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface BoothContentElement {
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothContentElement. */
export interface BoothContentElementConnection {
  __typename?: 'BoothContentElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothContentElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothContentElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
}

/** Autogenerated input type of BoothContentElementDelete */
export interface BoothContentElementDeleteInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothContentElementDelete */
export interface BoothContentElementDeletePayload {
  __typename?: 'BoothContentElementDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothContentElement` object deleted */
  element: BoothContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothContentElementEdge {
  __typename?: 'BoothContentElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothContentElement>;
}

/** Autogenerated input type of BoothCreate */
export interface BoothCreateInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** ID of the hall */
  hallId: Scalars['ID'];
  /** ID of the booth tier */
  boothTierId: Scalars['ID'];
  /** name of the booth design */
  appliedDesignCodename?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothCreate */
export interface BoothCreatePayload {
  __typename?: 'BoothCreatePayload';
  /** The `Booth` object created */
  booth: Booth;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesign extends Node {
  __typename?: 'BoothDesign';
  canvas?: Maybe<BoothDesignCanvasMasterElement>;
  event?: Maybe<Event>;
  id: Scalars['ID'];
  name: LocalizableString;
}

/** Action type of booth design button */
export enum BoothDesignButtonActionType {
  /** Chat */
  Chat = 'CHAT',
  /** Meetup */
  Meetup = 'MEETUP',
  /** External link */
  ExternalLink = 'EXTERNAL_LINK'
}

export interface BoothDesignCanvasElement extends Node, BoothV2DesignElement {
  __typename?: 'BoothDesignCanvasElement';
  aspectRatio: DisplayMeasurement;
  booth: Booth;
  height: DisplayMeasurement;
  /** ID of the object. */
  id: Scalars['ID'];
  layers: BoothV2DesignElementConnection;
  masterElement?: Maybe<BoothDesignMasterElement>;
  maxWidth: DisplayMeasurement;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  rawElement?: Maybe<BoothV2DesignElement>;
  width: DisplayMeasurement;
}


export interface BoothDesignCanvasElementLayersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothDesignCanvasMasterElement extends BoothDesignMasterElement {
  __typename?: 'BoothDesignCanvasMasterElement';
  aspectRatio: DisplayMeasurement;
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  layers: BoothDesignMasterElementConnection;
  maxWidth: DisplayMeasurement;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
  zOrder?: Maybe<Scalars['Int']>;
}


export interface BoothDesignCanvasMasterElementLayersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDesignCanvasMasterElementUpdate */
export interface BoothDesignCanvasMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Aspect ratio of element */
  aspectRatio?: Maybe<DisplayMeasurementInput>;
  /** Max width of element */
  maxWidth?: Maybe<DisplayMeasurementInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignCanvasMasterElementUpdate */
export interface BoothDesignCanvasMasterElementUpdatePayload {
  __typename?: 'BoothDesignCanvasMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignCanvasMasterElement` object updated */
  element: BoothDesignCanvasMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignColorFillElement extends Node, BoothV2DesignElement, BoothDesignCustomizableElement {
  __typename?: 'BoothDesignColorFillElement';
  booth: Booth;
  fillColor?: Maybe<Scalars['Color']>;
  height: DisplayMeasurement;
  /** ID of the object. */
  id: Scalars['ID'];
  keyName: Scalars['String'];
  maskImage: LocalizableImage;
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  rawElement?: Maybe<BoothV2DesignElement>;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignColorFillElementUpdate */
export interface BoothDesignColorFillElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  fillColor?: Maybe<Scalars['Color']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignColorFillElementUpdate */
export interface BoothDesignColorFillElementUpdatePayload {
  __typename?: 'BoothDesignColorFillElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignColorFillElement` object created */
  element: BoothDesignColorFillElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignColorFillMasterElement extends BoothDesignMasterElement, BoothDesignCustomizableMasterElement {
  __typename?: 'BoothDesignColorFillMasterElement';
  event: Event;
  fillColor?: Maybe<Scalars['Color']>;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  maskImage: LocalizableImage;
  masterControlledFillColor: Scalars['Boolean'];
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
  zOrder?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDesignColorFillMasterElementCreate */
export interface BoothDesignColorFillMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  fillColor?: Maybe<Scalars['Color']>;
  /** Mask image */
  maskImage?: Maybe<LocalizableFileInput>;
  /** To indicate whether the fill color will be mirrored by its child elements */
  masterControlledFillColor?: Maybe<Scalars['Boolean']>;
  /** Key name of element */
  keyName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignColorFillMasterElementCreate */
export interface BoothDesignColorFillMasterElementCreatePayload {
  __typename?: 'BoothDesignColorFillMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignColorFillMasterElement` object created */
  element: BoothDesignColorFillMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignColorFillMasterElementUpdate */
export interface BoothDesignColorFillMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  fillColor?: Maybe<Scalars['Color']>;
  /** Mask image */
  maskImage?: Maybe<LocalizableFileInput>;
  /** To indicate whether the fill color will be mirrored by its child elements */
  masterControlledFillColor?: Maybe<Scalars['Boolean']>;
  /** Updated key name */
  keyName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignColorFillMasterElementUpdate */
export interface BoothDesignColorFillMasterElementUpdatePayload {
  __typename?: 'BoothDesignColorFillMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignColorFillMasterElement` object updated */
  element: BoothDesignColorFillMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** The connection type for BoothDesign. */
export interface BoothDesignConnection {
  __typename?: 'BoothDesignConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothDesignEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothDesign>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface BoothDesignContentAreaElement extends Node, BoothV2DesignElement, BoothDesignCustomizableElement {
  __typename?: 'BoothDesignContentAreaElement';
  acceptedContentTypes: Array<BoothDesignContentType>;
  booth: Booth;
  contentImage: LocalizableImage;
  contentPdf: LocalizableFile;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  contentType?: Maybe<BoothDesignContentType>;
  contentVideo: LocalizableVideo;
  height: DisplayMeasurement;
  /** ID of the object. */
  id: Scalars['ID'];
  keyName: Scalars['String'];
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  rawElement?: Maybe<BoothV2DesignElement>;
  thumbnailImage: LocalizableImage;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignContentAreaElementUpdate */
export interface BoothDesignContentAreaElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** List of accepted content types */
  contentType?: Maybe<BoothDesignContentType>;
  /** Richtext raw content value when contentType is RICHTEXT */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value when contentType is RICHTEXT */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** Content image value when contentType is IMAGE */
  contentImage?: Maybe<LocalizableFileInput>;
  /** Content video value when contentType is VIDEO */
  contentVideo?: Maybe<LocalizableFileInput>;
  /** Content pdf value when contentType is PDF */
  contentPdf?: Maybe<LocalizableFileInput>;
  /** Thumbnail image value */
  thumbnailImage?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignContentAreaElementUpdate */
export interface BoothDesignContentAreaElementUpdatePayload {
  __typename?: 'BoothDesignContentAreaElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignContentAreaElement` object created */
  element: BoothDesignContentAreaElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignContentAreaMasterElement extends BoothDesignMasterElement, BoothDesignCustomizableMasterElement {
  __typename?: 'BoothDesignContentAreaMasterElement';
  acceptedContentTypes: Array<BoothDesignContentType>;
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
  zOrder?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDesignContentAreaMasterElementCreate */
export interface BoothDesignContentAreaMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** List of accepted content types */
  acceptedContentTypes?: Maybe<Array<BoothDesignContentType>>;
  /** Key name of element */
  keyName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignContentAreaMasterElementCreate */
export interface BoothDesignContentAreaMasterElementCreatePayload {
  __typename?: 'BoothDesignContentAreaMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignContentAreaMasterElement` object created */
  element: BoothDesignContentAreaMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignContentAreaMasterElementUpdate */
export interface BoothDesignContentAreaMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** List of accepted content types */
  acceptedContentTypes?: Maybe<Array<BoothDesignContentType>>;
  /** Updated key name */
  keyName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignContentAreaMasterElementUpdate */
export interface BoothDesignContentAreaMasterElementUpdatePayload {
  __typename?: 'BoothDesignContentAreaMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignContentAreaMasterElement` object updated */
  element: BoothDesignContentAreaMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Available content types for booth design */
export enum BoothDesignContentType {
  /** Image */
  Image = 'IMAGE',
  /** Richtext */
  Richtext = 'RICHTEXT',
  /** Video */
  Video = 'VIDEO',
  /** PDF */
  Pdf = 'PDF'
}

/** Autogenerated input type of BoothDesignCreate */
export interface BoothDesignCreateInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** name of the booth design */
  name: LocalizableStringInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignCreate */
export interface BoothDesignCreatePayload {
  __typename?: 'BoothDesignCreatePayload';
  /** The `BoothDesign` object created */
  boothDesign: BoothDesign;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignCustomizableElement {
  keyName: Scalars['String'];
}

export interface BoothDesignCustomizableMasterElement {
  keyName?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of BoothDesignDelete */
export interface BoothDesignDeleteInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** ID of the `BoothDesign` object to update */
  boothDesignId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignDelete */
export interface BoothDesignDeletePayload {
  __typename?: 'BoothDesignDeletePayload';
  /** The `BoothDesign` object deleted */
  boothDesign: BoothDesign;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothDesignEdge {
  __typename?: 'BoothDesignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothDesign>;
}

export interface BoothDesignElement extends Node {
  __typename?: 'BoothDesignElement';
  actionUrl?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Color']>;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  id: Scalars['ID'];
  image: LocalizableImage;
  kind?: Maybe<BoothDesignElementKind>;
  name: Scalars['String'];
  pdf: LocalizableFile;
  thumbnailImage: LocalizableImage;
  validKinds: Array<BoothDesignElementKind>;
  video: LocalizableVideo;
}

/** The connection type for BoothDesignElement. */
export interface BoothDesignElementConnection {
  __typename?: 'BoothDesignElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothDesignElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothDesignElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BoothDesignElementEdge {
  __typename?: 'BoothDesignElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothDesignElement>;
}

/** Kind of BoothDesignElement */
export enum BoothDesignElementKind {
  /** Color */
  Color = 'COLOR',
  /** Image */
  Image = 'IMAGE',
  /** Richtext */
  Richtext = 'RICHTEXT',
  /** Video */
  Video = 'VIDEO',
  /** PDF */
  Pdf = 'PDF'
}

/** Autogenerated input type of BoothDesignElementUpdate */
export interface BoothDesignElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** name of the design element to update */
  name: Scalars['String'];
  /** Updated kind of the booth design element */
  kind?: Maybe<BoothDesignElementKind>;
  /** Updated color value */
  color?: Maybe<Scalars['Color']>;
  /** Updated video file value */
  video?: Maybe<LocalizableFileInput>;
  /** Updated image file value */
  image?: Maybe<LocalizableFileInput>;
  /** Updated pdf file value */
  pdf?: Maybe<LocalizableFileInput>;
  /** Updated thumbnail image file value */
  thumbnailImage?: Maybe<LocalizableFileInput>;
  /** Updated raw richtext content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Updated HTML richtext content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** Action URL */
  actionUrl?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignElementUpdate */
export interface BoothDesignElementUpdatePayload {
  __typename?: 'BoothDesignElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignElement` object after update */
  designElement: BoothDesignElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignImageButtonElement extends Node, BoothV2DesignElement, BoothDesignCustomizableElement {
  __typename?: 'BoothDesignImageButtonElement';
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  actionType?: Maybe<BoothDesignButtonActionType>;
  booth: Booth;
  height: DisplayMeasurement;
  hoverStateImage: LocalizableImage;
  /** ID of the object. */
  id: Scalars['ID'];
  keyName: Scalars['String'];
  masterControlledAction: Scalars['Boolean'];
  masterElement?: Maybe<BoothDesignMasterElement>;
  normalStateImage: LocalizableImage;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  rawElement?: Maybe<BoothV2DesignElement>;
  width: DisplayMeasurement;
}

export interface BoothDesignImageButtonMasterElement extends BoothDesignMasterElement, BoothDesignCustomizableMasterElement {
  __typename?: 'BoothDesignImageButtonMasterElement';
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  actionType?: Maybe<BoothDesignButtonActionType>;
  event: Event;
  height: DisplayMeasurement;
  hoverStateImage: LocalizableImage;
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  masterControlledAction: Scalars['Boolean'];
  normalStateImage: LocalizableImage;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
  zOrder?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDesignImageButtonMasterElementCreate */
export interface BoothDesignImageButtonMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** The image to be shown when the button is in normal state */
  normalStateImage: LocalizableFileInput;
  /** The image to be shown when the button is in hover state */
  hoverStateImage: LocalizableFileInput;
  /** To indicate whether the button behaviour will be mirrored by its child elements */
  masterControlledAction?: Maybe<Scalars['Boolean']>;
  /** Button action type */
  actionType: BoothDesignButtonActionType;
  /** Link of redirection on button click */
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  /** Key name of element */
  keyName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageButtonMasterElementCreate */
export interface BoothDesignImageButtonMasterElementCreatePayload {
  __typename?: 'BoothDesignImageButtonMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageButtonMasterElement` object created */
  element: BoothDesignImageButtonMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignImageButtonMasterElementUpdate */
export interface BoothDesignImageButtonMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** The image to be shown when the button is in normal state */
  normalStateImage: LocalizableFileInput;
  /** The image to be shown when the button is in hover state */
  hoverStateImage: LocalizableFileInput;
  /** To indicate whether the button behaviour will be mirrored by its child elements */
  masterControlledAction?: Maybe<Scalars['Boolean']>;
  /** Button action type */
  actionType: BoothDesignButtonActionType;
  /** Link of redirection on button click */
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  /** Updated key name */
  keyName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageButtonMasterElementUpdate */
export interface BoothDesignImageButtonMasterElementUpdatePayload {
  __typename?: 'BoothDesignImageButtonMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageButtonMasterElement` object updated */
  element: BoothDesignImageButtonMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignImageElement extends Node, BoothV2DesignElement {
  __typename?: 'BoothDesignImageElement';
  booth: Booth;
  height: DisplayMeasurement;
  /** ID of the object. */
  id: Scalars['ID'];
  image: LocalizableImage;
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  rawElement?: Maybe<BoothV2DesignElement>;
  width: DisplayMeasurement;
}

export interface BoothDesignImageMasterElement extends BoothDesignMasterElement {
  __typename?: 'BoothDesignImageMasterElement';
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  image: LocalizableImage;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
  zOrder?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDesignImageMasterElementCreate */
export interface BoothDesignImageMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Element image */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageMasterElementCreate */
export interface BoothDesignImageMasterElementCreatePayload {
  __typename?: 'BoothDesignImageMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageMasterElement` object created */
  element: BoothDesignImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignImageMasterElementUpdate */
export interface BoothDesignImageMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Updated image */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageMasterElementUpdate */
export interface BoothDesignImageMasterElementUpdatePayload {
  __typename?: 'BoothDesignImageMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageMasterElement` object updated */
  element: BoothDesignImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignMasterElement {
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
  zOrder?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothDesignMasterElement. */
export interface BoothDesignMasterElementConnection {
  __typename?: 'BoothDesignMasterElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothDesignMasterElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothDesignMasterElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
}

/** Autogenerated input type of BoothDesignMasterElementDelete */
export interface BoothDesignMasterElementDeleteInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignMasterElementDelete */
export interface BoothDesignMasterElementDeletePayload {
  __typename?: 'BoothDesignMasterElementDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignMasterElement` object deleted */
  element: BoothDesignMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothDesignMasterElementEdge {
  __typename?: 'BoothDesignMasterElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothDesignMasterElement>;
}

/** Autogenerated input type of BoothDesignUpdate */
export interface BoothDesignUpdateInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** ID of the `BoothDesign` object to update */
  boothDesignId: Scalars['ID'];
  /** the updated name of the booth design */
  name: LocalizableStringInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignUpdate */
export interface BoothDesignUpdatePayload {
  __typename?: 'BoothDesignUpdatePayload';
  /** The `BoothDesign` object updated */
  boothDesign: BoothDesign;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDividerContentElement extends Node, BoothContentElement {
  __typename?: 'BoothDividerContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothDividerContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDividerContentElementCreate */
export interface BoothDividerContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerContentElementCreate */
export interface BoothDividerContentElementCreatePayload {
  __typename?: 'BoothDividerContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerContentElement` object created */
  element: BoothDividerContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDividerContentElementUpdate */
export interface BoothDividerContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerContentElementUpdate */
export interface BoothDividerContentElementUpdatePayload {
  __typename?: 'BoothDividerContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerContentElement` object updated */
  element: BoothDividerContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDividerMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothDividerMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothDividerMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDividerMasterElementCreate */
export interface BoothDividerMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerMasterElementCreate */
export interface BoothDividerMasterElementCreatePayload {
  __typename?: 'BoothDividerMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerMasterElement` object created */
  element: BoothDividerMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDividerMasterElementUpdate */
export interface BoothDividerMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerMasterElementUpdate */
export interface BoothDividerMasterElementUpdatePayload {
  __typename?: 'BoothDividerMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerMasterElement` object updated */
  element: BoothDividerMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothEdge {
  __typename?: 'BoothEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Booth>;
}

export interface BoothFileContentElement extends Node, BoothContentElement {
  __typename?: 'BoothFileContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  file: LocalizableFile;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothFileContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothFileContentElementCreate */
export interface BoothFileContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileContentElementCreate */
export interface BoothFileContentElementCreatePayload {
  __typename?: 'BoothFileContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileContentElement` object created */
  element: BoothFileContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothFileContentElementUpdate */
export interface BoothFileContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileContentElementUpdate */
export interface BoothFileContentElementUpdatePayload {
  __typename?: 'BoothFileContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileContentElement` object updated */
  element: BoothFileContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothFileMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothFileMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  file: LocalizableFile;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledFile: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothFileMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothFileMasterElementCreate */
export interface BoothFileMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the file content will be mirrored by its content elements */
  masterControlledFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileMasterElementCreate */
export interface BoothFileMasterElementCreatePayload {
  __typename?: 'BoothFileMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileMasterElement` object created */
  element: BoothFileMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothFileMasterElementUpdate */
export interface BoothFileMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the file content will be mirrored by its content elements */
  masterControlledFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileMasterElementUpdate */
export interface BoothFileMasterElementUpdatePayload {
  __typename?: 'BoothFileMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileMasterElement` object updated */
  element: BoothFileMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothImageContentElement extends Node, BoothContentElement {
  __typename?: 'BoothImageContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  image: LocalizableImage;
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothImageContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothImageContentElementCreate */
export interface BoothImageContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageContentElementCreate */
export interface BoothImageContentElementCreatePayload {
  __typename?: 'BoothImageContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageContentElement` object created */
  element: BoothImageContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothImageContentElementUpdate */
export interface BoothImageContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageContentElementUpdate */
export interface BoothImageContentElementUpdatePayload {
  __typename?: 'BoothImageContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageContentElement` object updated */
  element: BoothImageContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothImageMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothImageMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  image: LocalizableImage;
  masterControlledImage: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothImageMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothImageMasterElementCreate */
export interface BoothImageMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the image content will be mirrored by its content elements */
  masterControlledImage?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageMasterElementCreate */
export interface BoothImageMasterElementCreatePayload {
  __typename?: 'BoothImageMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageMasterElement` object created */
  element: BoothImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothImageMasterElementUpdate */
export interface BoothImageMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the image content will be mirrored by its content elements */
  masterControlledImage?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageMasterElementUpdate */
export interface BoothImageMasterElementUpdatePayload {
  __typename?: 'BoothImageMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageMasterElement` object updated */
  element: BoothImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothMasterElement {
  childElements: BoothMasterElementConnection;
  event: Event;
  id: Scalars['ID'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothMasterElement. */
export interface BoothMasterElementConnection {
  __typename?: 'BoothMasterElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothMasterElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothMasterElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
}

/** Autogenerated input type of BoothMasterElementDelete */
export interface BoothMasterElementDeleteInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothMasterElementDelete */
export interface BoothMasterElementDeletePayload {
  __typename?: 'BoothMasterElementDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothMasterElement` object deleted */
  element: BoothMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothMasterElementEdge {
  __typename?: 'BoothMasterElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothMasterElement>;
}

export interface BoothQuestionContentElement extends Node, BoothContentElement {
  __typename?: 'BoothQuestionContentElement';
  answer?: Maybe<BoothContentElement>;
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  question: LocalizableString;
  rawElement: BoothContentElement;
}


export interface BoothQuestionContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothQuestionContentElementCreate */
export interface BoothQuestionContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionContentElementCreate */
export interface BoothQuestionContentElementCreatePayload {
  __typename?: 'BoothQuestionContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionContentElement` object created */
  element: BoothQuestionContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothQuestionContentElementUpdate */
export interface BoothQuestionContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionContentElementUpdate */
export interface BoothQuestionContentElementUpdatePayload {
  __typename?: 'BoothQuestionContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionContentElement` object updated */
  element: BoothQuestionContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothQuestionMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothQuestionMasterElement';
  answer?: Maybe<BoothMasterElement>;
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledAnswer: Scalars['Boolean'];
  masterControlledQuestion: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  question: LocalizableString;
}


export interface BoothQuestionMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothQuestionMasterElementCreate */
export interface BoothQuestionMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the question content will be mirrored by its content elements */
  masterControlledQuestion?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the answer content will be mirrored by its content elements */
  masterControlledAnswer?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionMasterElementCreate */
export interface BoothQuestionMasterElementCreatePayload {
  __typename?: 'BoothQuestionMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionMasterElement` object created */
  element: BoothQuestionMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothQuestionMasterElementUpdate */
export interface BoothQuestionMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the question content will be mirrored by its content elements */
  masterControlledQuestion?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the answer content will be mirrored by its content elements */
  masterControlledAnswer?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionMasterElementUpdate */
export interface BoothQuestionMasterElementUpdatePayload {
  __typename?: 'BoothQuestionMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionMasterElement` object updated */
  element: BoothQuestionMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothReorder */
export interface BoothReorderInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the booth */
  boothId: Scalars['ID'];
  rowOrderPosition: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothReorder */
export interface BoothReorderPayload {
  __typename?: 'BoothReorderPayload';
  /** The `Booth` object after reordered */
  booth: Booth;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothRichtextContentElement extends Node, BoothContentElement {
  __typename?: 'BoothRichtextContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothRichtextContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothRichtextContentElementCreate */
export interface BoothRichtextContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextContentElementCreate */
export interface BoothRichtextContentElementCreatePayload {
  __typename?: 'BoothRichtextContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextContentElement` object created */
  element: BoothRichtextContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRichtextContentElementUpdate */
export interface BoothRichtextContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextContentElementUpdate */
export interface BoothRichtextContentElementUpdatePayload {
  __typename?: 'BoothRichtextContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextContentElement` object updated */
  element: BoothRichtextContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothRichtextMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothRichtextMasterElement';
  childElements: BoothMasterElementConnection;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledContent: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothRichtextMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothRichtextMasterElementCreate */
export interface BoothRichtextMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledContent?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextMasterElementCreate */
export interface BoothRichtextMasterElementCreatePayload {
  __typename?: 'BoothRichtextMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextMasterElement` object created */
  element: BoothRichtextMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRichtextMasterElementUpdate */
export interface BoothRichtextMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledContent?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextMasterElementUpdate */
export interface BoothRichtextMasterElementUpdatePayload {
  __typename?: 'BoothRichtextMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextMasterElement` object updated */
  element: BoothRichtextMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothRootContentElement extends Node, BoothContentElement {
  __typename?: 'BoothRootContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
  tabs: BoothTabContentElementConnection;
}


export interface BoothRootContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothRootContentElementTabsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothRootMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothRootMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledTabs: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  tabs: BoothTabMasterElementConnection;
}


export interface BoothRootMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothRootMasterElementTabsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothSupercategory extends Node, BoothTaxonomy {
  __typename?: 'BoothSupercategory';
  /** ID of the object. */
  id: Scalars['ID'];
  name: LocalizableString;
  subcategories: BoothTaxonomyConnection;
}


export interface BoothSupercategorySubcategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothTabContentElement extends Node, BoothContentElement {
  __typename?: 'BoothTabContentElement';
  body: BoothContentElementConnection;
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
  title: LocalizableString;
}


export interface BoothTabContentElementBodyArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothTabContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothTabContentElement. */
export interface BoothTabContentElementConnection {
  __typename?: 'BoothTabContentElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTabContentElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTabContentElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothTabContentElementCreate */
export interface BoothTabContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabContentElementCreate */
export interface BoothTabContentElementCreatePayload {
  __typename?: 'BoothTabContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabContentElement` object created */
  element: BoothTabContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothTabContentElementEdge {
  __typename?: 'BoothTabContentElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTabContentElement>;
}

/** Autogenerated input type of BoothTabContentElementUpdate */
export interface BoothTabContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabContentElementUpdate */
export interface BoothTabContentElementUpdatePayload {
  __typename?: 'BoothTabContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabContentElement` object updated */
  element: BoothTabContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothTabMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothTabMasterElement';
  body: BoothMasterElementConnection;
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledBody: Scalars['Boolean'];
  masterControlledTitle: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  title: LocalizableString;
}


export interface BoothTabMasterElementBodyArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothTabMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothTabMasterElement. */
export interface BoothTabMasterElementConnection {
  __typename?: 'BoothTabMasterElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTabMasterElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTabMasterElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothTabMasterElementCreate */
export interface BoothTabMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledTitle?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the body content will be mirrored by its content elements */
  masterControlledBody?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabMasterElementCreate */
export interface BoothTabMasterElementCreatePayload {
  __typename?: 'BoothTabMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabMasterElement` object created */
  element: BoothTabMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothTabMasterElementEdge {
  __typename?: 'BoothTabMasterElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTabMasterElement>;
}

/** Autogenerated input type of BoothTabMasterElementUpdate */
export interface BoothTabMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledTitle?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the body content will be mirrored by its content elements */
  masterControlledBody?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabMasterElementUpdate */
export interface BoothTabMasterElementUpdatePayload {
  __typename?: 'BoothTabMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabMasterElement` object updated */
  element: BoothTabMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothTaxonomy {
  id: Scalars['ID'];
  name: LocalizableString;
}

/** The connection type for BoothTaxonomy. */
export interface BoothTaxonomyConnection {
  __typename?: 'BoothTaxonomyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTaxonomyEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTaxonomy>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
}

/** An edge in a connection. */
export interface BoothTaxonomyEdge {
  __typename?: 'BoothTaxonomyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTaxonomy>;
}

export interface BoothTier extends Node {
  __typename?: 'BoothTier';
  booths: BoothConnection;
  id: Scalars['ID'];
  name: LocalizableString;
}


export interface BoothTierBoothsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothTier. */
export interface BoothTierConnection {
  __typename?: 'BoothTierConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTierEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTier>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BoothTierEdge {
  __typename?: 'BoothTierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTier>;
}

/** Autogenerated input type of BoothUpdate */
export interface BoothUpdateInput {
  /** ID of the booth */
  id: Scalars['ID'];
  /** Updated hall ID */
  hallId?: Maybe<Scalars['String']>;
  /** Updated booth number */
  boothNumber?: Maybe<Scalars['String']>;
  /** Updated booth tier */
  tierId?: Maybe<Scalars['ID']>;
  /** Updated website URL */
  website?: Maybe<Scalars['String']>;
  /** Updated name value */
  name?: Maybe<LocalizableStringInput>;
  /** Updated description value */
  description?: Maybe<LocalizableStringInput>;
  /** Updated logo file value */
  logo?: Maybe<LocalizableFileInput>;
  /** Updated exhibition name value */
  exhibitionName?: Maybe<LocalizableStringInput>;
  /** Updated design code */
  designCodename?: Maybe<Scalars['String']>;
  /** Updated 'meetupEnabled' value */
  meetupEnabled?: Maybe<Scalars['Boolean']>;
  /** Updated ids of categories */
  categories?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothUpdate */
export interface BoothUpdatePayload {
  __typename?: 'BoothUpdatePayload';
  /** The `Booth` object after update */
  booth?: Maybe<Booth>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothV2DesignElement {
  booth: Booth;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  rawElement?: Maybe<BoothV2DesignElement>;
  width: DisplayMeasurement;
}

/** The connection type for BoothV2DesignElement. */
export interface BoothV2DesignElementConnection {
  __typename?: 'BoothV2DesignElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothV2DesignElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothV2DesignElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
}

/** An edge in a connection. */
export interface BoothV2DesignElementEdge {
  __typename?: 'BoothV2DesignElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothV2DesignElement>;
}

export interface BoothVideoContentElement extends Node, BoothContentElement {
  __typename?: 'BoothVideoContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
  videoFile: LocalizableVideo;
}


export interface BoothVideoContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothVideoContentElementCreate */
export interface BoothVideoContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoContentElementCreate */
export interface BoothVideoContentElementCreatePayload {
  __typename?: 'BoothVideoContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoContentElement` object created */
  element: BoothVideoContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothVideoContentElementUpdate */
export interface BoothVideoContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoContentElementUpdate */
export interface BoothVideoContentElementUpdatePayload {
  __typename?: 'BoothVideoContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoContentElement` object updated */
  element: BoothVideoContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothVideoMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothVideoMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledVideoFile: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  videoFile: LocalizableVideo;
}


export interface BoothVideoMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothVideoMasterElementCreate */
export interface BoothVideoMasterElementCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the video content will be mirrored by its content elements */
  masterControlledVideoFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoMasterElementCreate */
export interface BoothVideoMasterElementCreatePayload {
  __typename?: 'BoothVideoMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoMasterElement` object created */
  element: BoothVideoMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothVideoMasterElementUpdate */
export interface BoothVideoMasterElementUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the video content will be mirrored by its content elements */
  masterControlledVideoFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoMasterElementUpdate */
export interface BoothVideoMasterElementUpdatePayload {
  __typename?: 'BoothVideoMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoMasterElement` object updated */
  element: BoothVideoMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}


/** Autogenerated input type of ConfirmationEmailResend */
export interface ConfirmationEmailResendInput {
  eventToken?: Maybe<Scalars['String']>;
  eventDomain?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ConfirmationEmailResend */
export interface ConfirmationEmailResendPayload {
  __typename?: 'ConfirmationEmailResendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Represents direct upload credentials */
export interface DirectUpload {
  __typename?: 'DirectUpload';
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars['String'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['SignedBlobID'];
  /** Upload URL */
  url: Scalars['String'];
}

export interface DisplayMeasurement {
  __typename?: 'DisplayMeasurement';
  dimension?: Maybe<Scalars['DisplayMeasurementDimension']>;
  unit: DisplayMeasurementUnit;
}


/** Input value of DisplayMeasurement */
export interface DisplayMeasurementInput {
  unit: DisplayMeasurementUnit;
  dimension?: Maybe<Scalars['DisplayMeasurementDimension']>;
}

export enum DisplayMeasurementUnit {
  /** Unit in pixel */
  Pixel = 'PIXEL',
  /** Unit in percentage */
  Percent = 'PERCENT'
}

export interface Event extends Node {
  __typename?: 'Event';
  attendeeByUniqueToken?: Maybe<Attendee>;
  attendeesByKeyword?: Maybe<RongcloudUserConnection>;
  attendeesByUuids?: Maybe<RongcloudUserConnection>;
  booth?: Maybe<Booth>;
  boothDesigns: BoothDesignConnection;
  boothTaxonomies: BoothTaxonomyConnection;
  boothTiers: BoothTierConnection;
  booths: BoothConnection;
  boothsByCategories: BoothConnection;
  defaultLocale: Scalars['Locale'];
  features: Array<EventFeature>;
  halls: HallConnection;
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  name?: Maybe<LocalizableString>;
  rootMasterElement: BoothRootMasterElement;
  searchByKeyword: SearchResultConnection;
  vexpoEventUrl?: Maybe<Scalars['String']>;
}


export interface EventAttendeeByUniqueTokenArgs {
  uniqueToken: Scalars['String'];
}


export interface EventAttendeesByKeywordArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}


export interface EventAttendeesByUuidsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  uuids: Array<Scalars['String']>;
}


export interface EventBoothArgs {
  id: Scalars['ID'];
}


export interface EventBoothDesignsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothTaxonomiesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothTiersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothsByCategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
}


export interface EventHallsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventSearchByKeywordArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}

/** Available features for `Event` */
export enum EventFeature {
  /** Product page */
  ProductPage = 'PRODUCT_PAGE',
  /** Exhibitor report */
  ExhibitorReport = 'EXHIBITOR_REPORT'
}

export interface File extends Fileable {
  __typename?: 'File';
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
}

export interface Fileable {
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
}

/** Autogenerated input type of GroupChatJoin */
export interface GroupChatJoinInput {
  uniqueToken: Scalars['String'];
  groupId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of GroupChatJoin */
export interface GroupChatJoinPayload {
  __typename?: 'GroupChatJoinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rongcloudGroup?: Maybe<RongcloudGroup>;
  success: Scalars['Boolean'];
}

/** Autogenerated input type of GroupVideoCallRoomCreate */
export interface GroupVideoCallRoomCreateInput {
  boothId: Scalars['String'];
  participantId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of GroupVideoCallRoomCreate */
export interface GroupVideoCallRoomCreatePayload {
  __typename?: 'GroupVideoCallRoomCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  roomAdmin?: Maybe<RongcloudUser>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
  /** The `VideoCallRoom` object after create */
  videoCallRoom?: Maybe<VideoCallRoom>;
}

/** Autogenerated input type of GroupVideoCallRoomJoinResponse */
export interface GroupVideoCallRoomJoinResponseInput {
  roomToken: Scalars['String'];
  inviterId: Scalars['String'];
  participantId: Scalars['String'];
  isApproved: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of GroupVideoCallRoomJoinResponse */
export interface GroupVideoCallRoomJoinResponsePayload {
  __typename?: 'GroupVideoCallRoomJoinResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Hall extends Node {
  __typename?: 'Hall';
  booths: BoothConnection;
  id: Scalars['ID'];
  name: LocalizableString;
}


export interface HallBoothsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for Hall. */
export interface HallConnection {
  __typename?: 'HallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HallEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Hall>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface HallEdge {
  __typename?: 'HallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Hall>;
}

export interface Image extends Fileable {
  __typename?: 'Image';
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
  originalImageUrl: Scalars['String'];
}

/** File information required to prepare a direct upload */
export interface InitiateDirectUploadInput {
  /** Original file name */
  filename: Scalars['String'];
  /** File size (bytes) */
  byteSize: Scalars['Int'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String'];
  /** File content type */
  contentType: Scalars['String'];
}

/** Autogenerated return type of InitiateDirectUpload */
export interface InitiateDirectUploadPayload {
  __typename?: 'InitiateDirectUploadPayload';
  directUpload: DirectUpload;
}



export interface LocalizableFile {
  __typename?: 'LocalizableFile';
  values?: Maybe<Array<LocalizableFileValue>>;
}


export interface LocalizableFileValuesArgs {
  locales: Array<Scalars['Locale']>;
}

/** Container for multiple file translations values */
export interface LocalizableFileInput {
  values?: Maybe<Array<LocalizableFileInputValue>>;
}

/** Container for single file translation value */
export interface LocalizableFileInputValue {
  value?: Maybe<Scalars['SignedBlobID']>;
  locale: Scalars['Locale'];
}

export interface LocalizableFileValue {
  __typename?: 'LocalizableFileValue';
  locale: Scalars['Locale'];
  value?: Maybe<File>;
}

/** Localizable file-like objects */
export type LocalizableFileable = LocalizableFile | LocalizableImage | LocalizableVideo;

export interface LocalizableImage {
  __typename?: 'LocalizableImage';
  values?: Maybe<Array<LocalizableImageValue>>;
}


export interface LocalizableImageValuesArgs {
  locales: Array<Scalars['Locale']>;
}

export interface LocalizableImageValue {
  __typename?: 'LocalizableImageValue';
  locale: Scalars['Locale'];
  value?: Maybe<Image>;
}

export interface LocalizableString {
  __typename?: 'LocalizableString';
  values?: Maybe<Array<LocalizableStringValue>>;
}


export interface LocalizableStringValuesArgs {
  locales: Array<Scalars['Locale']>;
}

/** Container for multiple string translations values */
export interface LocalizableStringInput {
  values?: Maybe<Array<LocalizableStringInputValue>>;
}

/** Container for single string translation value */
export interface LocalizableStringInputValue {
  value?: Maybe<Scalars['String']>;
  locale: Scalars['Locale'];
}

export interface LocalizableStringValue {
  __typename?: 'LocalizableStringValue';
  locale: Scalars['Locale'];
  value?: Maybe<Scalars['String']>;
}

export interface LocalizableVideo {
  __typename?: 'LocalizableVideo';
  values?: Maybe<Array<LocalizableVideoValue>>;
}


export interface LocalizableVideoValuesArgs {
  locales: Array<Scalars['Locale']>;
}

export interface LocalizableVideoValue {
  __typename?: 'LocalizableVideoValue';
  locale: Scalars['Locale'];
  value?: Maybe<Video>;
}

export interface Mutation {
  __typename?: 'Mutation';
  addContact?: Maybe<AddContactPayload>;
  boothContentElementDelete?: Maybe<BoothContentElementDeletePayload>;
  boothCreate?: Maybe<BoothCreatePayload>;
  boothDesignCanvasMasterElementUpdate?: Maybe<BoothDesignCanvasMasterElementUpdatePayload>;
  boothDesignColorFillElementUpdate?: Maybe<BoothDesignColorFillElementUpdatePayload>;
  boothDesignColorFillMasterElementCreate?: Maybe<BoothDesignColorFillMasterElementCreatePayload>;
  boothDesignColorFillMasterElementUpdate?: Maybe<BoothDesignColorFillMasterElementUpdatePayload>;
  boothDesignContentAreaElementUpdate?: Maybe<BoothDesignContentAreaElementUpdatePayload>;
  boothDesignContentAreaMasterElementCreate?: Maybe<BoothDesignContentAreaMasterElementCreatePayload>;
  boothDesignContentAreaMasterElementUpdate?: Maybe<BoothDesignContentAreaMasterElementUpdatePayload>;
  boothDesignCreate?: Maybe<BoothDesignCreatePayload>;
  boothDesignDelete?: Maybe<BoothDesignDeletePayload>;
  boothDesignElementUpdate?: Maybe<BoothDesignElementUpdatePayload>;
  boothDesignImageButtonMasterElementCreate?: Maybe<BoothDesignImageButtonMasterElementCreatePayload>;
  boothDesignImageButtonMasterElementUpdate?: Maybe<BoothDesignImageButtonMasterElementUpdatePayload>;
  boothDesignImageMasterElementCreate?: Maybe<BoothDesignImageMasterElementCreatePayload>;
  boothDesignImageMasterElementUpdate?: Maybe<BoothDesignImageMasterElementUpdatePayload>;
  boothDesignMasterElementDelete?: Maybe<BoothDesignMasterElementDeletePayload>;
  boothDesignUpdate?: Maybe<BoothDesignUpdatePayload>;
  boothDividerContentElementCreate?: Maybe<BoothDividerContentElementCreatePayload>;
  boothDividerContentElementUpdate?: Maybe<BoothDividerContentElementUpdatePayload>;
  boothDividerMasterElementCreate?: Maybe<BoothDividerMasterElementCreatePayload>;
  boothDividerMasterElementUpdate?: Maybe<BoothDividerMasterElementUpdatePayload>;
  boothFileContentElementCreate?: Maybe<BoothFileContentElementCreatePayload>;
  boothFileContentElementUpdate?: Maybe<BoothFileContentElementUpdatePayload>;
  boothFileMasterElementCreate?: Maybe<BoothFileMasterElementCreatePayload>;
  boothFileMasterElementUpdate?: Maybe<BoothFileMasterElementUpdatePayload>;
  boothImageContentElementCreate?: Maybe<BoothImageContentElementCreatePayload>;
  boothImageContentElementUpdate?: Maybe<BoothImageContentElementUpdatePayload>;
  boothImageMasterElementCreate?: Maybe<BoothImageMasterElementCreatePayload>;
  boothImageMasterElementUpdate?: Maybe<BoothImageMasterElementUpdatePayload>;
  boothMasterElementDelete?: Maybe<BoothMasterElementDeletePayload>;
  boothQuestionContentElementCreate?: Maybe<BoothQuestionContentElementCreatePayload>;
  boothQuestionContentElementUpdate?: Maybe<BoothQuestionContentElementUpdatePayload>;
  boothQuestionMasterElementCreate?: Maybe<BoothQuestionMasterElementCreatePayload>;
  boothQuestionMasterElementUpdate?: Maybe<BoothQuestionMasterElementUpdatePayload>;
  boothReorder?: Maybe<BoothReorderPayload>;
  boothRichtextContentElementCreate?: Maybe<BoothRichtextContentElementCreatePayload>;
  boothRichtextContentElementUpdate?: Maybe<BoothRichtextContentElementUpdatePayload>;
  boothRichtextMasterElementCreate?: Maybe<BoothRichtextMasterElementCreatePayload>;
  boothRichtextMasterElementUpdate?: Maybe<BoothRichtextMasterElementUpdatePayload>;
  boothTabContentElementCreate?: Maybe<BoothTabContentElementCreatePayload>;
  boothTabContentElementUpdate?: Maybe<BoothTabContentElementUpdatePayload>;
  boothTabMasterElementCreate?: Maybe<BoothTabMasterElementCreatePayload>;
  boothTabMasterElementUpdate?: Maybe<BoothTabMasterElementUpdatePayload>;
  boothUpdate?: Maybe<BoothUpdatePayload>;
  boothVideoContentElementCreate?: Maybe<BoothVideoContentElementCreatePayload>;
  boothVideoContentElementUpdate?: Maybe<BoothVideoContentElementUpdatePayload>;
  boothVideoMasterElementCreate?: Maybe<BoothVideoMasterElementCreatePayload>;
  boothVideoMasterElementUpdate?: Maybe<BoothVideoMasterElementUpdatePayload>;
  confirmationEmailResend?: Maybe<ConfirmationEmailResendPayload>;
  groupChatJoin?: Maybe<GroupChatJoinPayload>;
  groupVideoCallRoomCreate?: Maybe<GroupVideoCallRoomCreatePayload>;
  groupVideoCallRoomJoinResponse?: Maybe<GroupVideoCallRoomJoinResponsePayload>;
  /** Initiate direct file upload */
  initiateDirectUpload?: Maybe<InitiateDirectUploadPayload>;
  pushNotification?: Maybe<PushNotificationPayload>;
  removeContact?: Maybe<RemoveContactPayload>;
  uploadFile?: Maybe<UploadFilePayload>;
  videoCallParticipantAccept?: Maybe<VideoCallParticipantAcceptPayload>;
  videoCallParticipantJoin?: Maybe<VideoCallParticipantJoinPayload>;
  videoCallParticipantQuit?: Maybe<VideoCallParticipantQuitPayload>;
  videoCallRoomCreate?: Maybe<VideoCallRoomCreatePayload>;
  videoCallTokenCreate?: Maybe<VideoCallTokenCreatePayload>;
}


export interface MutationAddContactArgs {
  input: AddContactInput;
}


export interface MutationBoothContentElementDeleteArgs {
  input: BoothContentElementDeleteInput;
}


export interface MutationBoothCreateArgs {
  input: BoothCreateInput;
}


export interface MutationBoothDesignCanvasMasterElementUpdateArgs {
  input: BoothDesignCanvasMasterElementUpdateInput;
}


export interface MutationBoothDesignColorFillElementUpdateArgs {
  input: BoothDesignColorFillElementUpdateInput;
}


export interface MutationBoothDesignColorFillMasterElementCreateArgs {
  input: BoothDesignColorFillMasterElementCreateInput;
}


export interface MutationBoothDesignColorFillMasterElementUpdateArgs {
  input: BoothDesignColorFillMasterElementUpdateInput;
}


export interface MutationBoothDesignContentAreaElementUpdateArgs {
  input: BoothDesignContentAreaElementUpdateInput;
}


export interface MutationBoothDesignContentAreaMasterElementCreateArgs {
  input: BoothDesignContentAreaMasterElementCreateInput;
}


export interface MutationBoothDesignContentAreaMasterElementUpdateArgs {
  input: BoothDesignContentAreaMasterElementUpdateInput;
}


export interface MutationBoothDesignCreateArgs {
  input: BoothDesignCreateInput;
}


export interface MutationBoothDesignDeleteArgs {
  input: BoothDesignDeleteInput;
}


export interface MutationBoothDesignElementUpdateArgs {
  input: BoothDesignElementUpdateInput;
}


export interface MutationBoothDesignImageButtonMasterElementCreateArgs {
  input: BoothDesignImageButtonMasterElementCreateInput;
}


export interface MutationBoothDesignImageButtonMasterElementUpdateArgs {
  input: BoothDesignImageButtonMasterElementUpdateInput;
}


export interface MutationBoothDesignImageMasterElementCreateArgs {
  input: BoothDesignImageMasterElementCreateInput;
}


export interface MutationBoothDesignImageMasterElementUpdateArgs {
  input: BoothDesignImageMasterElementUpdateInput;
}


export interface MutationBoothDesignMasterElementDeleteArgs {
  input: BoothDesignMasterElementDeleteInput;
}


export interface MutationBoothDesignUpdateArgs {
  input: BoothDesignUpdateInput;
}


export interface MutationBoothDividerContentElementCreateArgs {
  input: BoothDividerContentElementCreateInput;
}


export interface MutationBoothDividerContentElementUpdateArgs {
  input: BoothDividerContentElementUpdateInput;
}


export interface MutationBoothDividerMasterElementCreateArgs {
  input: BoothDividerMasterElementCreateInput;
}


export interface MutationBoothDividerMasterElementUpdateArgs {
  input: BoothDividerMasterElementUpdateInput;
}


export interface MutationBoothFileContentElementCreateArgs {
  input: BoothFileContentElementCreateInput;
}


export interface MutationBoothFileContentElementUpdateArgs {
  input: BoothFileContentElementUpdateInput;
}


export interface MutationBoothFileMasterElementCreateArgs {
  input: BoothFileMasterElementCreateInput;
}


export interface MutationBoothFileMasterElementUpdateArgs {
  input: BoothFileMasterElementUpdateInput;
}


export interface MutationBoothImageContentElementCreateArgs {
  input: BoothImageContentElementCreateInput;
}


export interface MutationBoothImageContentElementUpdateArgs {
  input: BoothImageContentElementUpdateInput;
}


export interface MutationBoothImageMasterElementCreateArgs {
  input: BoothImageMasterElementCreateInput;
}


export interface MutationBoothImageMasterElementUpdateArgs {
  input: BoothImageMasterElementUpdateInput;
}


export interface MutationBoothMasterElementDeleteArgs {
  input: BoothMasterElementDeleteInput;
}


export interface MutationBoothQuestionContentElementCreateArgs {
  input: BoothQuestionContentElementCreateInput;
}


export interface MutationBoothQuestionContentElementUpdateArgs {
  input: BoothQuestionContentElementUpdateInput;
}


export interface MutationBoothQuestionMasterElementCreateArgs {
  input: BoothQuestionMasterElementCreateInput;
}


export interface MutationBoothQuestionMasterElementUpdateArgs {
  input: BoothQuestionMasterElementUpdateInput;
}


export interface MutationBoothReorderArgs {
  input: BoothReorderInput;
}


export interface MutationBoothRichtextContentElementCreateArgs {
  input: BoothRichtextContentElementCreateInput;
}


export interface MutationBoothRichtextContentElementUpdateArgs {
  input: BoothRichtextContentElementUpdateInput;
}


export interface MutationBoothRichtextMasterElementCreateArgs {
  input: BoothRichtextMasterElementCreateInput;
}


export interface MutationBoothRichtextMasterElementUpdateArgs {
  input: BoothRichtextMasterElementUpdateInput;
}


export interface MutationBoothTabContentElementCreateArgs {
  input: BoothTabContentElementCreateInput;
}


export interface MutationBoothTabContentElementUpdateArgs {
  input: BoothTabContentElementUpdateInput;
}


export interface MutationBoothTabMasterElementCreateArgs {
  input: BoothTabMasterElementCreateInput;
}


export interface MutationBoothTabMasterElementUpdateArgs {
  input: BoothTabMasterElementUpdateInput;
}


export interface MutationBoothUpdateArgs {
  input: BoothUpdateInput;
}


export interface MutationBoothVideoContentElementCreateArgs {
  input: BoothVideoContentElementCreateInput;
}


export interface MutationBoothVideoContentElementUpdateArgs {
  input: BoothVideoContentElementUpdateInput;
}


export interface MutationBoothVideoMasterElementCreateArgs {
  input: BoothVideoMasterElementCreateInput;
}


export interface MutationBoothVideoMasterElementUpdateArgs {
  input: BoothVideoMasterElementUpdateInput;
}


export interface MutationConfirmationEmailResendArgs {
  input: ConfirmationEmailResendInput;
}


export interface MutationGroupChatJoinArgs {
  input: GroupChatJoinInput;
}


export interface MutationGroupVideoCallRoomCreateArgs {
  input: GroupVideoCallRoomCreateInput;
}


export interface MutationGroupVideoCallRoomJoinResponseArgs {
  input: GroupVideoCallRoomJoinResponseInput;
}


export interface MutationInitiateDirectUploadArgs {
  input: InitiateDirectUploadInput;
}


export interface MutationPushNotificationArgs {
  input: PushNotificationInput;
}


export interface MutationRemoveContactArgs {
  input: RemoveContactInput;
}


export interface MutationUploadFileArgs {
  input: UploadFileInput;
}


export interface MutationVideoCallParticipantAcceptArgs {
  input: VideoCallParticipantAcceptInput;
}


export interface MutationVideoCallParticipantJoinArgs {
  input: VideoCallParticipantJoinInput;
}


export interface MutationVideoCallParticipantQuitArgs {
  input: VideoCallParticipantQuitInput;
}


export interface MutationVideoCallRoomCreateArgs {
  input: VideoCallRoomCreateInput;
}


export interface MutationVideoCallTokenCreateArgs {
  input: VideoCallTokenCreateInput;
}

/** An object with an ID. */
export interface Node {
  /** ID of the object. */
  id: Scalars['ID'];
}

/** Information about pagination in a connection. */
export interface PageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of PushNotification */
export interface PushNotificationInput {
  eventToken?: Maybe<Scalars['String']>;
  eventDomain?: Maybe<Scalars['String']>;
  uniqueToken: Scalars['String'];
  message: Scalars['String'];
  sender: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of PushNotification */
export interface PushNotificationPayload {
  __typename?: 'PushNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Query {
  __typename?: 'Query';
  attendeeByUniqueToken?: Maybe<Attendee>;
  attendeesByEventToken?: Maybe<RongcloudUserConnection>;
  contactsByUuid?: Maybe<UserContactGroupConnection>;
  eventByDomain?: Maybe<Event>;
  eventByToken?: Maybe<Event>;
  fetchAttendeeInformation?: Maybe<Array<RongcloudUser>>;
  localizableFileableBy?: Maybe<LocalizableFileable>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  rongcloudGroup?: Maybe<RongcloudGroup>;
  videoCallRoomByToken?: Maybe<VideoCallRoom>;
}


export interface QueryAttendeeByUniqueTokenArgs {
  eventToken: Scalars['String'];
  uniqueToken: Scalars['String'];
}


export interface QueryAttendeesByEventTokenArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  eventToken: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
}


export interface QueryContactsByUuidArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
}


export interface QueryEventByDomainArgs {
  domain: Scalars['String'];
}


export interface QueryEventByTokenArgs {
  eventToken: Scalars['String'];
}


export interface QueryFetchAttendeeInformationArgs {
  eventToken: Scalars['String'];
  ids: Array<Scalars['String']>;
}


export interface QueryLocalizableFileableByArgs {
  objectId: Scalars['ID'];
  fieldName: Scalars['String'];
}


export interface QueryNodeArgs {
  id: Scalars['ID'];
}


export interface QueryNodesArgs {
  ids: Array<Scalars['ID']>;
}


export interface QueryRongcloudGroupArgs {
  rongcloudId: Scalars['String'];
}


export interface QueryVideoCallRoomByTokenArgs {
  token: Scalars['String'];
}

/** Autogenerated input type of RemoveContact */
export interface RemoveContactInput {
  userUuid: Scalars['String'];
  contactUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of RemoveContact */
export interface RemoveContactPayload {
  __typename?: 'RemoveContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
}

/** RongcloudGroup */
export interface RongcloudGroup {
  __typename?: 'RongcloudGroup';
  rongcloudUsers: RongcloudUserConnection;
}


/** RongcloudGroup */
export interface RongcloudGroupRongcloudUsersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}

/** RongcloudUser */
export interface RongcloudUser {
  __typename?: 'RongcloudUser';
  customAttendeeFields?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  rongcloudToken?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
}

/** The connection type for RongcloudUser. */
export interface RongcloudUserConnection {
  __typename?: 'RongcloudUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RongcloudUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RongcloudUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface RongcloudUserEdge {
  __typename?: 'RongcloudUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RongcloudUser>;
}

export interface SearchResult extends Node {
  __typename?: 'SearchResult';
  id: Scalars['ID'];
  searchable: Searchable;
}

/** The connection type for SearchResult. */
export interface SearchResultConnection {
  __typename?: 'SearchResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SearchResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface SearchResultEdge {
  __typename?: 'SearchResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchResult>;
}

/** Searchable objects */
export type Searchable = Booth;

export interface SharedAttachment {
  __typename?: 'SharedAttachment';
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
  id: Scalars['Int'];
}


/** Standard error information */
export interface StandardError {
  __typename?: 'StandardError';
  details: Array<StandardErrorDetail>;
  message: Scalars['String'];
  path: Array<Scalars['StandardErrorPathSegment']>;
  type?: Maybe<Scalars['String']>;
}

/** Standard error detail */
export interface StandardErrorDetail {
  __typename?: 'StandardErrorDetail';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}



/** Autogenerated input type of UploadFile */
export interface UploadFileInput {
  eventToken?: Maybe<Scalars['String']>;
  eventDomain?: Maybe<Scalars['String']>;
  /** Upload file */
  file: Scalars['Upload'];
  uniqueToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of UploadFile */
export interface UploadFilePayload {
  __typename?: 'UploadFilePayload';
  attachment?: Maybe<SharedAttachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface UserContactGroup {
  __typename?: 'UserContactGroup';
  contact: RongcloudUser;
  createdAt: Scalars['String'];
}

/** The connection type for UserContactGroup. */
export interface UserContactGroupConnection {
  __typename?: 'UserContactGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserContactGroupEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserContactGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface UserContactGroupEdge {
  __typename?: 'UserContactGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserContactGroup>;
}

export interface Video extends Fileable {
  __typename?: 'Video';
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
  playbackUrl: Scalars['String'];
}

/** Autogenerated input type of VideoCallParticipantAccept */
export interface VideoCallParticipantAcceptInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallParticipantAccept */
export interface VideoCallParticipantAcceptPayload {
  __typename?: 'VideoCallParticipantAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of VideoCallParticipantJoin */
export interface VideoCallParticipantJoinInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallParticipantJoin */
export interface VideoCallParticipantJoinPayload {
  __typename?: 'VideoCallParticipantJoinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of VideoCallParticipantQuit */
export interface VideoCallParticipantQuitInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallParticipantQuit */
export interface VideoCallParticipantQuitPayload {
  __typename?: 'VideoCallParticipantQuitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface VideoCallRoom extends Node {
  __typename?: 'VideoCallRoom';
  id: Scalars['ID'];
  participants: RongcloudUserConnection;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
}


export interface VideoCallRoomParticipantsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of VideoCallRoomCreate */
export interface VideoCallRoomCreateInput {
  inviterId: Scalars['String'];
  inviteeIds: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallRoomCreate */
export interface VideoCallRoomCreatePayload {
  __typename?: 'VideoCallRoomCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
  /** The `VideoCallRoom` object after create */
  videoCallRoom: VideoCallRoom;
}

/** VideoCallRoomMember */
export interface VideoCallRoomMember extends Node {
  __typename?: 'VideoCallRoomMember';
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  twilioToken?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of VideoCallTokenCreate */
export interface VideoCallTokenCreateInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallTokenCreate */
export interface VideoCallTokenCreatePayload {
  __typename?: 'VideoCallTokenCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  participant: RongcloudUser;
  roomMember?: Maybe<VideoCallRoomMember>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
  videoCallRoom?: Maybe<VideoCallRoom>;
}

export type BoothQuestionContentRawElementFragmentFragment = (
  { __typename: 'BoothQuestionContentElement' }
  & Pick<BoothQuestionContentElement, 'id'>
  & { question: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), answer?: Maybe<{ __typename?: 'BoothDividerContentElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | (
      { __typename?: 'BoothFileMasterElement' }
      & BoothFileMasterElementFragmentFragment
    ) | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | (
      { __typename?: 'BoothFileContentElement' }
      & BoothFileContentRawElementFragmentFragment
    ) | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
    & BoothFileContentRawElementFragmentFragment
  ) | (
    { __typename?: 'BoothImageContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | (
      { __typename?: 'BoothImageMasterElement' }
      & BoothImageMasterElementFragmentFragment
    ) | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | (
      { __typename?: 'BoothImageContentElement' }
      & BoothImageContentRawElementFragmentFragment
    ) | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
    & BoothImageContentRawElementFragmentFragment
  ) | { __typename?: 'BoothQuestionContentElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | (
      { __typename?: 'BoothRichtextMasterElement' }
      & BoothRichtextMasterElementFragmentFragment
    ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | (
      { __typename?: 'BoothRichtextContentElement' }
      & BoothRichtextContentRawElementFragmentFragment
    ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
    & BoothRichtextContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | (
    { __typename?: 'BoothVideoContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | (
      { __typename?: 'BoothVideoMasterElement' }
      & BoothVideoMasterElementFragmentFragment
    )>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | (
      { __typename?: 'BoothVideoContentElement' }
      & BoothVideoContentRawElementFragmentFragment
    ) }
    & BoothVideoContentRawElementFragmentFragment
  )> }
);

export type BoothQuestionMasterElementFragmentFragment = (
  { __typename: 'BoothQuestionMasterElement' }
  & Pick<BoothQuestionMasterElement, 'id' | 'masterControlledAnswer' | 'masterControlledQuestion'>
  & { question: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), answer?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileMasterElement' }
    & BoothFileMasterElementFragmentFragment
  ) | (
    { __typename?: 'BoothImageMasterElement' }
    & BoothImageMasterElementFragmentFragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextMasterElement' }
    & BoothRichtextMasterElementFragmentFragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | (
    { __typename?: 'BoothVideoMasterElement' }
    & BoothVideoMasterElementFragmentFragment
  )> }
);

export type BoothRichtextContentRawElementFragmentFragment = (
  { __typename: 'BoothRichtextContentElement' }
  & Pick<BoothRichtextContentElement, 'id'>
  & { contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), contentRichtextRaw: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ) }
);

export type BoothRichtextMasterElementFragmentFragment = (
  { __typename: 'BoothRichtextMasterElement' }
  & Pick<BoothRichtextMasterElement, 'id' | 'masterControlledContent'>
  & { contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), contentRichtextRaw: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ) }
);

export type BoothVideoContentRawElementFragmentFragment = (
  { __typename: 'BoothVideoContentElement' }
  & Pick<BoothVideoContentElement, 'id'>
  & { videoFile: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ) }
);

export type BoothVideoMasterElementFragmentFragment = (
  { __typename: 'BoothVideoMasterElement' }
  & Pick<BoothVideoMasterElement, 'id' | 'masterControlledVideoFile'>
  & { videoFile: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ) }
);

export type BoothFileContentRawElementFragmentFragment = (
  { __typename: 'BoothFileContentElement' }
  & Pick<BoothFileContentElement, 'id'>
  & { file: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ) }
);

export type BoothFileMasterElementFragmentFragment = (
  { __typename: 'BoothFileMasterElement' }
  & Pick<BoothFileMasterElement, 'id' | 'masterControlledFile'>
  & { file: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ) }
);

export type BoothImageContentRawElementFragmentFragment = (
  { __typename: 'BoothImageContentElement' }
  & Pick<BoothImageContentElement, 'id'>
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
);

export type BoothImageMasterElementFragmentFragment = (
  { __typename: 'BoothImageMasterElement' }
  & Pick<BoothImageMasterElement, 'id' | 'masterControlledImage'>
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
);

export type BoothFileContentElementFragmentFragment = (
  { __typename?: 'BoothFileContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileMasterElement' }
    & BoothFileMasterElementFragmentFragment
  ) | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & BoothFileContentRawElementFragmentFragment
  ) | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothFileContentRawElementFragmentFragment
);

export type BoothImageContentElementFragmentFragment = (
  { __typename?: 'BoothImageContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | (
    { __typename?: 'BoothImageMasterElement' }
    & BoothImageMasterElementFragmentFragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | (
    { __typename?: 'BoothImageContentElement' }
    & BoothImageContentRawElementFragmentFragment
  ) | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothImageContentRawElementFragmentFragment
);

export type BoothQuestionContentElementFragmentFragment = (
  { __typename?: 'BoothQuestionContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | (
    { __typename?: 'BoothQuestionMasterElement' }
    & BoothQuestionMasterElementFragmentFragment
  ) | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | (
    { __typename?: 'BoothQuestionContentElement' }
    & BoothQuestionContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothQuestionContentRawElementFragmentFragment
);

export type BoothRichtextContentElementFragmentFragment = (
  { __typename?: 'BoothRichtextContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextMasterElement' }
    & BoothRichtextMasterElementFragmentFragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & BoothRichtextContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothRichtextContentRawElementFragmentFragment
);

export type BoothVideoContentElementFragmentFragment = (
  { __typename?: 'BoothVideoContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | (
    { __typename?: 'BoothVideoMasterElement' }
    & BoothVideoMasterElementFragmentFragment
  )>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & BoothRichtextContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothVideoContentRawElementFragmentFragment
);

type BoothContentElementFragment_BoothDividerContentElement_Fragment = (
  { __typename: 'BoothDividerContentElement' }
  & Pick<BoothDividerContentElement, 'id'>
);

type BoothContentElementFragment_BoothFileContentElement_Fragment = (
  { __typename: 'BoothFileContentElement' }
  & Pick<BoothFileContentElement, 'id'>
  & BoothFileContentElementFragmentFragment
);

type BoothContentElementFragment_BoothImageContentElement_Fragment = (
  { __typename: 'BoothImageContentElement' }
  & Pick<BoothImageContentElement, 'id'>
  & BoothImageContentElementFragmentFragment
);

type BoothContentElementFragment_BoothQuestionContentElement_Fragment = (
  { __typename: 'BoothQuestionContentElement' }
  & Pick<BoothQuestionContentElement, 'id'>
  & BoothQuestionContentElementFragmentFragment
);

type BoothContentElementFragment_BoothRichtextContentElement_Fragment = (
  { __typename: 'BoothRichtextContentElement' }
  & Pick<BoothRichtextContentElement, 'id'>
  & BoothRichtextContentElementFragmentFragment
);

type BoothContentElementFragment_BoothRootContentElement_Fragment = (
  { __typename: 'BoothRootContentElement' }
  & Pick<BoothRootContentElement, 'id'>
);

type BoothContentElementFragment_BoothTabContentElement_Fragment = (
  { __typename: 'BoothTabContentElement' }
  & Pick<BoothTabContentElement, 'id'>
);

type BoothContentElementFragment_BoothVideoContentElement_Fragment = (
  { __typename: 'BoothVideoContentElement' }
  & Pick<BoothVideoContentElement, 'id'>
  & BoothVideoContentElementFragmentFragment
);

export type BoothContentElementFragmentFragment = BoothContentElementFragment_BoothDividerContentElement_Fragment | BoothContentElementFragment_BoothFileContentElement_Fragment | BoothContentElementFragment_BoothImageContentElement_Fragment | BoothContentElementFragment_BoothQuestionContentElement_Fragment | BoothContentElementFragment_BoothRichtextContentElement_Fragment | BoothContentElementFragment_BoothRootContentElement_Fragment | BoothContentElementFragment_BoothTabContentElement_Fragment | BoothContentElementFragment_BoothVideoContentElement_Fragment;

export type BoothDesignElementFramgmentFragment = (
  { __typename?: 'BoothDesignElement' }
  & Pick<BoothDesignElement, 'id' | 'name' | 'validKinds' | 'kind' | 'color' | 'actionUrl'>
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), video: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ), pdf: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ), contentRichtextRaw: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), thumbnailImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
);

export type BoothDesignElementsFragmentFragment = (
  { __typename?: 'BoothDesignElementConnection' }
  & { nodes?: Maybe<Array<Maybe<(
    { __typename?: 'BoothDesignElement' }
    & BoothDesignElementFramgmentFragment
  )>>> }
);

export type EventAllFragment = (
  { __typename: 'Event' }
  & Pick<Event, 'id' | 'features' | 'vexpoEventUrl' | 'defaultLocale' | 'locales'>
  & { name?: Maybe<(
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  )> }
);

export type LocalizableVideoFragmentFragment = (
  { __typename: 'LocalizableVideo' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableVideoValue' }
    & Pick<LocalizableVideoValue, 'locale'>
    & { value?: Maybe<(
      { __typename?: 'Video' }
      & Pick<Video, 'filename' | 'playbackUrl'>
    )> }
  )>> }
);

export type LocalizableFileFragmentFragment = (
  { __typename: 'LocalizableFile' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableFileValue' }
    & Pick<LocalizableFileValue, 'locale'>
    & { value?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'filename' | 'fileUrl'>
    )> }
  )>> }
);

export type LocalizableImageFragmentFragment = (
  { __typename: 'LocalizableImage' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableImageValue' }
    & Pick<LocalizableImageValue, 'locale'>
    & { value?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'filename' | 'originalImageUrl'>
    )> }
  )>> }
);

export type LocalizableStringFragmentFragment = (
  { __typename: 'LocalizableString' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableStringValue' }
    & Pick<LocalizableStringValue, 'locale' | 'value'>
  )>> }
);

export type GetEventLocaleByDomainQueryVariables = Exact<{
  eventDomain: Scalars['String'];
}>;


export type GetEventLocaleByDomainQuery = (
  { __typename?: 'Query' }
  & { eventByDomain?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'defaultLocale'>
  )> }
);

export type GetEventByDomainQueryVariables = Exact<{
  eventDomain: Scalars['String'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetEventByDomainQuery = (
  { __typename?: 'Query' }
  & { eventByDomain?: Maybe<(
    { __typename?: 'Event' }
    & EventAllFragment
  )> }
);

export type GetEventByDomainTestQueryVariables = Exact<{
  eventDomain: Scalars['String'];
}>;


export type GetEventByDomainTestQuery = (
  { __typename?: 'Query' }
  & { eventByDomain?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type GetEventLocaleByTokenQueryVariables = Exact<{
  eventToken: Scalars['String'];
}>;


export type GetEventLocaleByTokenQuery = (
  { __typename?: 'Query' }
  & { eventByToken?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'defaultLocale'>
  )> }
);

export type GetEventByTokenQueryVariables = Exact<{
  eventToken: Scalars['String'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetEventByTokenQuery = (
  { __typename?: 'Query' }
  & { eventByToken?: Maybe<(
    { __typename?: 'Event' }
    & EventAllFragment
  )> }
);

export type GetEventByTokenTestQueryVariables = Exact<{
  eventToken: Scalars['String'];
}>;


export type GetEventByTokenTestQuery = (
  { __typename?: 'Query' }
  & { eventByToken?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type GetEventLocaleQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type GetEventLocaleQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & Pick<Event, 'defaultLocale'>
  ) | { __typename?: 'Hall' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetNodeTestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetNodeTestQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename: 'Booth' } | { __typename: 'BoothCategory' } | { __typename: 'BoothDesign' } | { __typename: 'BoothDesignCanvasElement' } | { __typename: 'BoothDesignColorFillElement' } | { __typename: 'BoothDesignContentAreaElement' } | { __typename: 'BoothDesignElement' } | { __typename: 'BoothDesignImageButtonElement' } | { __typename: 'BoothDesignImageElement' } | { __typename: 'BoothDividerContentElement' } | { __typename: 'BoothDividerMasterElement' } | { __typename: 'BoothFileContentElement' } | { __typename: 'BoothFileMasterElement' } | { __typename: 'BoothImageContentElement' } | { __typename: 'BoothImageMasterElement' } | { __typename: 'BoothQuestionContentElement' } | { __typename: 'BoothQuestionMasterElement' } | { __typename: 'BoothRichtextContentElement' } | { __typename: 'BoothRichtextMasterElement' } | { __typename: 'BoothRootContentElement' } | { __typename: 'BoothRootMasterElement' } | { __typename: 'BoothSupercategory' } | { __typename: 'BoothTabContentElement' } | { __typename: 'BoothTabMasterElement' } | { __typename: 'BoothTier' } | { __typename: 'BoothVideoContentElement' } | { __typename: 'BoothVideoMasterElement' } | { __typename: 'Event' } | { __typename: 'Hall' } | { __typename: 'SearchResult' } | { __typename: 'VideoCallRoom' } | { __typename: 'VideoCallRoomMember' }> }
);

export type GetNodeQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetNodeQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & EventAllFragment
  ) | { __typename?: 'Hall' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }>, booth?: Maybe<(
    { __typename?: 'Booth' }
    & BoothAllFragment
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'Hall' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothAllFragment = (
  { __typename: 'Booth' }
  & Pick<Booth, 'id' | 'appliedDesignCodename' | 'boothNumber' | 'website'>
  & { name: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), tier: (
    { __typename?: 'BoothTier' }
    & { name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ) }
  ), event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & { name?: Maybe<(
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    )> }
  ) }
);

export const LocalizableFileFragmentFragmentDoc = gql`
    fragment LocalizableFileFragment on LocalizableFile {
  __typename
  values(locales: $locales) {
    locale
    value {
      filename
      fileUrl
    }
  }
}
    `;
export const BoothFileContentRawElementFragmentFragmentDoc = gql`
    fragment BoothFileContentRawElementFragment on BoothFileContentElement {
  id
  __typename
  file {
    ...LocalizableFileFragment
  }
}
    ${LocalizableFileFragmentFragmentDoc}`;
export const BoothFileMasterElementFragmentFragmentDoc = gql`
    fragment BoothFileMasterElementFragment on BoothFileMasterElement {
  id
  __typename
  masterControlledFile
  file {
    ...LocalizableFileFragment
  }
}
    ${LocalizableFileFragmentFragmentDoc}`;
export const BoothFileContentElementFragmentFragmentDoc = gql`
    fragment BoothFileContentElementFragment on BoothFileContentElement {
  ...BoothFileContentRawElementFragment
  masterElement {
    ...BoothFileMasterElementFragment
  }
  rawElement {
    ...BoothFileContentRawElementFragment
  }
}
    ${BoothFileContentRawElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}`;
export const LocalizableImageFragmentFragmentDoc = gql`
    fragment LocalizableImageFragment on LocalizableImage {
  __typename
  values(locales: $locales) {
    locale
    value {
      filename
      originalImageUrl
    }
  }
}
    `;
export const BoothImageContentRawElementFragmentFragmentDoc = gql`
    fragment BoothImageContentRawElementFragment on BoothImageContentElement {
  id
  __typename
  image {
    ...LocalizableImageFragment
  }
}
    ${LocalizableImageFragmentFragmentDoc}`;
export const BoothImageMasterElementFragmentFragmentDoc = gql`
    fragment BoothImageMasterElementFragment on BoothImageMasterElement {
  id
  __typename
  masterControlledImage
  image {
    ...LocalizableImageFragment
  }
}
    ${LocalizableImageFragmentFragmentDoc}`;
export const BoothImageContentElementFragmentFragmentDoc = gql`
    fragment BoothImageContentElementFragment on BoothImageContentElement {
  ...BoothImageContentRawElementFragment
  masterElement {
    ...BoothImageMasterElementFragment
  }
  rawElement {
    ...BoothImageContentRawElementFragment
  }
}
    ${BoothImageContentRawElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}`;
export const LocalizableStringFragmentFragmentDoc = gql`
    fragment LocalizableStringFragment on LocalizableString {
  __typename
  values(locales: $locales) {
    locale
    value
  }
}
    `;
export const BoothRichtextContentRawElementFragmentFragmentDoc = gql`
    fragment BoothRichtextContentRawElementFragment on BoothRichtextContentElement {
  id
  __typename
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
  contentRichtextRaw {
    ...LocalizableStringFragment
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothRichtextMasterElementFragmentFragmentDoc = gql`
    fragment BoothRichtextMasterElementFragment on BoothRichtextMasterElement {
  id
  __typename
  masterControlledContent
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
  contentRichtextRaw {
    ...LocalizableStringFragment
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const LocalizableVideoFragmentFragmentDoc = gql`
    fragment LocalizableVideoFragment on LocalizableVideo {
  __typename
  values(locales: $locales) {
    locale
    value {
      filename
      playbackUrl
    }
  }
}
    `;
export const BoothVideoContentRawElementFragmentFragmentDoc = gql`
    fragment BoothVideoContentRawElementFragment on BoothVideoContentElement {
  id
  __typename
  videoFile {
    ...LocalizableVideoFragment
  }
}
    ${LocalizableVideoFragmentFragmentDoc}`;
export const BoothVideoMasterElementFragmentFragmentDoc = gql`
    fragment BoothVideoMasterElementFragment on BoothVideoMasterElement {
  id
  __typename
  masterControlledVideoFile
  videoFile {
    ...LocalizableVideoFragment
  }
}
    ${LocalizableVideoFragmentFragmentDoc}`;
export const BoothQuestionContentRawElementFragmentFragmentDoc = gql`
    fragment BoothQuestionContentRawElementFragment on BoothQuestionContentElement {
  id
  __typename
  question {
    ...LocalizableStringFragment
  }
  answer {
    ... on BoothRichtextContentElement {
      ...BoothRichtextContentRawElementFragment
      masterElement {
        ...BoothRichtextMasterElementFragment
      }
      rawElement {
        ...BoothRichtextContentRawElementFragment
      }
    }
    ... on BoothVideoContentElement {
      ...BoothVideoContentRawElementFragment
      masterElement {
        ...BoothVideoMasterElementFragment
      }
      rawElement {
        ...BoothVideoContentRawElementFragment
      }
    }
    ... on BoothFileContentElement {
      ...BoothFileContentRawElementFragment
      masterElement {
        ...BoothFileMasterElementFragment
      }
      rawElement {
        ...BoothFileContentRawElementFragment
      }
    }
    ... on BoothImageContentElement {
      ...BoothImageContentRawElementFragment
      masterElement {
        ...BoothImageMasterElementFragment
      }
      rawElement {
        ...BoothImageContentRawElementFragment
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${BoothRichtextContentRawElementFragmentFragmentDoc}
${BoothRichtextMasterElementFragmentFragmentDoc}
${BoothVideoContentRawElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}
${BoothFileContentRawElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}
${BoothImageContentRawElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}`;
export const BoothQuestionMasterElementFragmentFragmentDoc = gql`
    fragment BoothQuestionMasterElementFragment on BoothQuestionMasterElement {
  id
  __typename
  masterControlledAnswer
  masterControlledQuestion
  question {
    ...LocalizableStringFragment
  }
  answer {
    ...BoothRichtextMasterElementFragment
    ...BoothImageMasterElementFragment
    ...BoothFileMasterElementFragment
    ...BoothVideoMasterElementFragment
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${BoothRichtextMasterElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}`;
export const BoothQuestionContentElementFragmentFragmentDoc = gql`
    fragment BoothQuestionContentElementFragment on BoothQuestionContentElement {
  ...BoothQuestionContentRawElementFragment
  masterElement {
    ...BoothQuestionMasterElementFragment
  }
  rawElement {
    ...BoothQuestionContentRawElementFragment
  }
}
    ${BoothQuestionContentRawElementFragmentFragmentDoc}
${BoothQuestionMasterElementFragmentFragmentDoc}`;
export const BoothRichtextContentElementFragmentFragmentDoc = gql`
    fragment BoothRichtextContentElementFragment on BoothRichtextContentElement {
  ...BoothRichtextContentRawElementFragment
  masterElement {
    ...BoothRichtextMasterElementFragment
  }
  rawElement {
    ...BoothRichtextContentRawElementFragment
  }
}
    ${BoothRichtextContentRawElementFragmentFragmentDoc}
${BoothRichtextMasterElementFragmentFragmentDoc}`;
export const BoothVideoContentElementFragmentFragmentDoc = gql`
    fragment BoothVideoContentElementFragment on BoothVideoContentElement {
  ...BoothVideoContentRawElementFragment
  masterElement {
    ...BoothVideoMasterElementFragment
  }
  rawElement {
    ...BoothRichtextContentRawElementFragment
  }
}
    ${BoothVideoContentRawElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}
${BoothRichtextContentRawElementFragmentFragmentDoc}`;
export const BoothContentElementFragmentFragmentDoc = gql`
    fragment BoothContentElementFragment on BoothContentElement {
  id
  __typename
  ...BoothFileContentElementFragment
  ...BoothImageContentElementFragment
  ...BoothQuestionContentElementFragment
  ...BoothRichtextContentElementFragment
  ...BoothVideoContentElementFragment
}
    ${BoothFileContentElementFragmentFragmentDoc}
${BoothImageContentElementFragmentFragmentDoc}
${BoothQuestionContentElementFragmentFragmentDoc}
${BoothRichtextContentElementFragmentFragmentDoc}
${BoothVideoContentElementFragmentFragmentDoc}`;
export const BoothDesignElementFramgmentFragmentDoc = gql`
    fragment BoothDesignElementFramgment on BoothDesignElement {
  id
  name
  validKinds
  kind
  color
  image {
    ...LocalizableImageFragment
  }
  video {
    ...LocalizableVideoFragment
  }
  pdf {
    ...LocalizableFileFragment
  }
  contentRichtextRaw {
    ...LocalizableStringFragment
  }
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
  thumbnailImage {
    ...LocalizableImageFragment
  }
  actionUrl
}
    ${LocalizableImageFragmentFragmentDoc}
${LocalizableVideoFragmentFragmentDoc}
${LocalizableFileFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}`;
export const BoothDesignElementsFragmentFragmentDoc = gql`
    fragment BoothDesignElementsFragment on BoothDesignElementConnection {
  nodes {
    ...BoothDesignElementFramgment
  }
}
    ${BoothDesignElementFramgmentFragmentDoc}`;
export const EventAllFragmentDoc = gql`
    fragment EventAll on Event {
  __typename
  id
  features
  vexpoEventUrl
  name {
    ...LocalizableStringFragment
  }
  defaultLocale
  locales
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothAllFragmentDoc = gql`
    fragment BoothAll on Booth {
  __typename
  id
  name {
    ...LocalizableStringFragment
  }
  appliedDesignCodename
  boothNumber
  tier {
    name {
      ...LocalizableStringFragment
    }
  }
  website
  event {
    id
    name {
      ...LocalizableStringFragment
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetEventLocaleByDomainDocument = gql`
    query getEventLocaleByDomain($eventDomain: String!) {
  eventByDomain(domain: $eventDomain) {
    defaultLocale
  }
}
    `;
export const GetEventByDomainDocument = gql`
    query getEventByDomain($eventDomain: String!, $locales: [Locale!]!) {
  eventByDomain(domain: $eventDomain) {
    ...EventAll
  }
}
    ${EventAllFragmentDoc}`;
export const GetEventByDomainTestDocument = gql`
    query getEventByDomainTest($eventDomain: String!) {
  eventByDomain(domain: $eventDomain) {
    id
  }
}
    `;
export const GetEventLocaleByTokenDocument = gql`
    query getEventLocaleByToken($eventToken: String!) {
  eventByToken(eventToken: $eventToken) {
    defaultLocale
  }
}
    `;
export const GetEventByTokenDocument = gql`
    query getEventByToken($eventToken: String!, $locales: [Locale!]!) {
  eventByToken(eventToken: $eventToken) {
    ...EventAll
  }
}
    ${EventAllFragmentDoc}`;
export const GetEventByTokenTestDocument = gql`
    query getEventByTokenTest($eventToken: String!) {
  eventByToken(eventToken: $eventToken) {
    id
  }
}
    `;
export const GetEventLocaleDocument = gql`
    query getEventLocale($eventId: ID!) {
  node(id: $eventId) {
    ... on Event {
      defaultLocale
    }
  }
}
    `;
export const GetNodeTestDocument = gql`
    query getNodeTest($id: ID!) {
  node(id: $id) {
    __typename
  }
}
    `;
export const GetNodeDocument = gql`
    query getNode($id: ID!, $locales: [Locale!]!) {
  event: node(id: $id) {
    ...EventAll
  }
  booth: node(id: $id) {
    ...BoothAll
  }
}
    ${EventAllFragmentDoc}
${BoothAllFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getEventLocaleByDomain(variables: GetEventLocaleByDomainQueryVariables): Promise<GetEventLocaleByDomainQuery> {
      return withWrapper(() => client.request<GetEventLocaleByDomainQuery>(print(GetEventLocaleByDomainDocument), variables));
    },
    getEventByDomain(variables: GetEventByDomainQueryVariables): Promise<GetEventByDomainQuery> {
      return withWrapper(() => client.request<GetEventByDomainQuery>(print(GetEventByDomainDocument), variables));
    },
    getEventByDomainTest(variables: GetEventByDomainTestQueryVariables): Promise<GetEventByDomainTestQuery> {
      return withWrapper(() => client.request<GetEventByDomainTestQuery>(print(GetEventByDomainTestDocument), variables));
    },
    getEventLocaleByToken(variables: GetEventLocaleByTokenQueryVariables): Promise<GetEventLocaleByTokenQuery> {
      return withWrapper(() => client.request<GetEventLocaleByTokenQuery>(print(GetEventLocaleByTokenDocument), variables));
    },
    getEventByToken(variables: GetEventByTokenQueryVariables): Promise<GetEventByTokenQuery> {
      return withWrapper(() => client.request<GetEventByTokenQuery>(print(GetEventByTokenDocument), variables));
    },
    getEventByTokenTest(variables: GetEventByTokenTestQueryVariables): Promise<GetEventByTokenTestQuery> {
      return withWrapper(() => client.request<GetEventByTokenTestQuery>(print(GetEventByTokenTestDocument), variables));
    },
    getEventLocale(variables: GetEventLocaleQueryVariables): Promise<GetEventLocaleQuery> {
      return withWrapper(() => client.request<GetEventLocaleQuery>(print(GetEventLocaleDocument), variables));
    },
    getNodeTest(variables: GetNodeTestQueryVariables): Promise<GetNodeTestQuery> {
      return withWrapper(() => client.request<GetNodeTestQuery>(print(GetNodeTestDocument), variables));
    },
    getNode(variables: GetNodeQueryVariables): Promise<GetNodeQuery> {
      return withWrapper(() => client.request<GetNodeQuery>(print(GetNodeDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;