import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AppRoutingModule } from './app-routing.module';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { EventTokenEntryComponent } from './components/info-entry/event-token-entry/event-token-entry.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfoEntryContainerComponent } from './components/info-entry-container/info-entry-container.component';
import { InfoEntryDirective } from 'src/app/components/info-entry-directive/info-entry.directive';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EventDomainEntryComponent } from './components/info-entry/event-domain-entry/event-domain-entry.component';
import { EventContentComponent } from './components/info-entry/common/event-content/event-content.component';
import { NodeEventEntryComponent } from './components/info-entry/node/node-event-entry/node-event-entry.component';
import { NodeEntryComponent } from './components/info-entry/node/node-entry/node-entry.component';
import { NodeEntryDirective } from 'src/app/components/info-entry/node/node-entry-directive/node-entry.directive';
import { MatChipsModule } from '@angular/material/chips';
import { NodeEntryDefaultComponent } from './components/info-entry/node/node-entry-default/node-entry-default.component';
import { NodeEntryBoothComponent } from './components/info-entry/node/node-entry-booth/node-entry-booth.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchBarComponent,
    EventTokenEntryComponent,
    SearchResultComponent,
    InfoEntryContainerComponent,
    InfoEntryDirective,
    EventDomainEntryComponent,
    EventContentComponent,
    NodeEventEntryComponent,
    NodeEntryComponent,
    NodeEntryDirective,
    NodeEntryDefaultComponent,
    NodeEntryBoothComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatSnackBarModule,
    MatChipsModule,
    AppRoutingModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
