import { Injectable, NgZone } from '@angular/core';
import { from, Observable } from 'rxjs';
import { infoEntries, InfoEntry } from 'src/app/models/info-entry';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environments } from 'src/app/models/environment';
import { runInZone } from 'src/utils/runInZone';

interface SearchHandler {
  fetchResult(text: string): Observable<InfoEntry>;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  handlers: SearchHandler[] = [
    this.eventByTokenHandler,
    this.eventByDomainHandler,
    this.nodeIdHandler,
  ];

  constructor(readonly zone: NgZone) {
  }

  get eventByTokenHandler(): SearchHandler {
    return {
      fetchResult(text: string): Observable<InfoEntry> {
        return from(environments).pipe(
          mergeMap(({ id, sdk }) => sdk.getEventByTokenTest({ eventToken: text }).pipe(
            filter(resp => resp.eventByToken?.id != null),
            map(() => (infoEntries.eventToken(id, text))),
          )),
        );
      },
    };
  }

  get eventByDomainHandler(): SearchHandler {
    return {
      fetchResult(text: string): Observable<InfoEntry> {
        return from(environments).pipe(
          mergeMap(({ id, sdk }) => sdk.getEventByDomainTest({ eventDomain: text }).pipe(
            filter(resp => resp.eventByDomain?.id != null),
            map(() => (infoEntries.eventDomain(id, text))),
          )),
        );
      },
    };
  }

  get nodeIdHandler(): SearchHandler {
    return {
      fetchResult(text: string): Observable<InfoEntry> {
        return from(environments).pipe(
          mergeMap(({ id, sdk }) => sdk.getNodeTest({ id: text }).pipe(
            filter(resp => resp.node?.__typename != null),
            map(() => {
              return (infoEntries.nodeId(id, text));
            }),
          )),
        );
      },
    };
  }

  search(text: string): Observable<InfoEntry> {
    return from(this.handlers).pipe(
      mergeMap(handler => handler.fetchResult(text)),
      runInZone(this.zone),
    );
  }
}
