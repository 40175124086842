import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, Directive, Input, ViewContainerRef } from '@angular/core';
import { InfoEntry } from 'src/app/models/info-entry';

@Directive({
  selector: '[appInfoEntry]',
})
export class InfoEntryDirective implements AfterViewInit {

  @Input()
  infoEntry: InfoEntry;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.infoEntry.component);
    this.viewContainerRef.clear();
    const component = this.viewContainerRef.createComponent(componentFactory);
    component.instance.infoEntry = this.infoEntry;
    this.ref.detectChanges();
  }
}
