import { AfterContentInit, Component, Input, NgZone, OnInit } from '@angular/core';
import { InfoEntry } from 'src/app/models/info-entry';
import { map, mergeMap } from 'rxjs/operators';
import { runInZone } from 'src/utils/runInZone';
import { Event, Node } from 'src/models';

@Component({
  selector: 'app-node-entry',
  templateUrl: './node-entry.component.html',
  styleUrls: ['./node-entry.component.scss'],
})
export class NodeEntryComponent implements OnInit, AfterContentInit {
  @Input()
  infoEntry: InfoEntry;

  node: Node;

  constructor(readonly zone: NgZone) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    if (this.infoEntry != null) {
      const { sdk } = this.infoEntry.environment;
      sdk.getNode({ id: this.infoEntry.text, locales: ['en'] }).pipe(
        runInZone(this.zone),
      ).subscribe((resp) => {
        this.node = Object.values(resp).find(value => Object.values(value).length > 0) as Node;
        console.log(resp);
      });
    }
  }

}
