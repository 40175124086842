import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, Directive, Input, Type, ViewContainerRef } from '@angular/core';
import { Node } from 'src/models';
import { NodeEventEntryComponent } from 'src/app/components/info-entry/node/node-event-entry/node-event-entry.component';
import { NodeEntryDefaultComponent } from 'src/app/components/info-entry/node/node-entry-default/node-entry-default.component';
import { NodeEntryBoothComponent } from 'src/app/components/info-entry/node/node-entry-booth/node-entry-booth.component';

@Directive({
  selector: '[appNodeEntry]',
})
export class NodeEntryDirective implements AfterViewInit {

  @Input()
  node: Node;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(getNodeEntryComponent(this.node));
    this.viewContainerRef.clear();
    const component = this.viewContainerRef.createComponent(componentFactory);
    component.instance.node = this.node;
    this.ref.detectChanges();
  }
}


function getNodeEntryComponent(node: Node): Type<any> {
  switch (node.__typename) {
    case 'Event':
      return NodeEventEntryComponent;
    case 'Booth':
      return NodeEntryBoothComponent;
  }
  return NodeEntryDefaultComponent;
}
