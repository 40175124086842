import { Component, Input, OnInit } from '@angular/core';
import { InfoEntry } from 'src/app/models/info-entry';

@Component({
  selector: 'app-info-entry-container',
  templateUrl: './info-entry-container.component.html',
  styleUrls: ['./info-entry-container.component.scss'],
})
export class InfoEntryContainerComponent implements OnInit {

  @Input()
  infoEntry: InfoEntry;

  constructor() {
  }

  ngOnInit(): void {
  }

}
