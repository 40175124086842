import { getSdk } from 'src/models';
import { GraphQLClient } from 'graphql-request';
import { defer, Observable } from 'rxjs';

export const API_ENDPOINT_PRODUCTION = 'https://api-virtual.eventxtra.com/graphql';
export const API_ENDPOINT_STAGING = 'https://api-virtual.2vanx.com/graphql';

const sdkTypeToken = getSdk(new GraphQLClient(API_ENDPOINT_PRODUCTION));
export type CmsSDK = typeof sdkTypeToken;

export type ExtractPromiseType<T> = T extends PromiseLike<infer U> ? U : T

const sdkToObservables = (sdk: CmsSDK) => Object.entries(sdk).reduce((mapped: CmsSDKObservables, [key, value]) => {
  mapped[key] = (...params) => defer(() => (value as any)(params[0]));
  return mapped;
}, {} as CmsSDKObservables);

export type CmsSDKObservables = {
  [key in keyof CmsSDK]: ((...param: Parameters<CmsSDK[key]>) =>
    Observable<ExtractPromiseType<ReturnType<CmsSDK[key]>>>)
};

export const createSDKs = () => ({
  staging: sdkToObservables(getSdk(new GraphQLClient(API_ENDPOINT_STAGING))),
  production: sdkToObservables(getSdk(new GraphQLClient(API_ENDPOINT_PRODUCTION))),
});
