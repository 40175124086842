import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from 'src/app/services/search.service';
import { Subscription } from 'rxjs';
import { InfoEntry } from 'src/app/models/info-entry';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly searchService: SearchService,
    readonly snackBar: MatSnackBar,
    readonly zone: NgZone,
    readonly meta: Meta,
  ) {
  }

  searchString: string = null;

  disposable: Subscription = null;

  resultEntries: InfoEntry[] = [];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => this.search(params.text));
  }

  search(searchString): void {
    this.resultEntries = [];
    this.searchString = searchString;
    this.disposable = this.searchService.search(searchString).subscribe(
      (entry) => this.resultEntries.push(entry),
      (err) => {
        this.snackBar.open(err.message, 'dismiss');
        console.log(err);
      },
      () => {
        this.disposable = null;
      },
    );
  }

}
