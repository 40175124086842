<div *ngIf="node!=null">
  <div>
    Booth ID: {{node.id}}
  </div>
  <div>
    Event: {{node.event.name.values[0].value}} (<a [routerLink]="'/search/'+node.event.id">{{node.event.id}}</a> )
  </div>
  <div>
    Booth Number: {{node.boothNumber}}
  </div>
  <div>
    Booth Tier: {{boothTier}}
  </div>
  <div>
    Applied Design Codename: {{node.appliedDesignCodename}}
  </div>
  <div>
    Booth website: {{node.website}}
  </div>
</div>
