import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  search = '';

  constructor(readonly router: Router) {
  }

  ngOnInit(): void {
  }

  onEnter(): void {
    this.router.navigate([`search/${encodeURIComponent(this.search)}`]);
  }
}
