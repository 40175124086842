import { Component } from '@angular/core';
import { createSDKs } from 'src/utils/graphql-requests';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
}
