import { AfterContentInit, Component, Input, NgZone, OnInit } from '@angular/core';
import { InfoEntry } from 'src/app/models/info-entry';
import { Event } from 'src/models';
import { map, mergeMap } from 'rxjs/operators';
import { runInZone } from 'src/utils/runInZone';

@Component({
  selector: 'app-event-domain-entry',
  templateUrl: './event-domain-entry.component.html',
  styleUrls: ['./event-domain-entry.component.scss'],
})
export class EventDomainEntryComponent implements OnInit, AfterContentInit {
  @Input()
  infoEntry: InfoEntry;

  event: Event;

  constructor(private zone: NgZone) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    if (this.infoEntry != null) {
      const { sdk } = this.infoEntry.environment;
      sdk.getEventLocaleByDomain({ eventDomain: this.infoEntry.text }).pipe(
        map(resp => resp.eventByDomain.defaultLocale),
        mergeMap((locale) => sdk.getEventByDomain({ eventDomain: this.infoEntry.text, locales: [locale] })),
        runInZone(this.zone),
      ).subscribe((event) => {
        this.event = event.eventByDomain as unknown as Event;
      });
    }
  }

}
