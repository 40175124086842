import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTokenEntryComponent } from 'src/app/components/info-entry/event-token-entry/event-token-entry.component';
import { RouterModule, Routes } from '@angular/router';
import { SearchResultComponent } from 'src/app/components/search-result/search-result.component';


const routes: Routes = [
  { path: 'eventToken/:env/:eventToken', component: EventTokenEntryComponent },
  { path: 'search/:text', component: SearchResultComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
}),
    CommonModule,
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
