<mat-progress-bar mode="indeterminate" *ngIf="event==null"></mat-progress-bar>
<h2>Event token: {{infoEntry?.text}}</h2>
<mat-chip-list>
  <mat-chip selected
            [color]="infoEntry.environment.id === 'staging'?'warn':'primary'">
    ENV: {{infoEntry.environment.id}}</mat-chip>
</mat-chip-list>
<div style="margin-top: 20px;">
  <app-event-content [event]="event"></app-event-content>
</div>
